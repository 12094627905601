import React, { Component } from "react";
import axios from "axios";
import DataTable from "react-data-table-component";
import ExpandableComponent from "./ExpandableComponent";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";

const { REACT_APP_MURL } = process.env;
/*
Filter Temporaril;y Disabled
to reenable and fix change datatable state from merged to
*/

const FilterComponent = ({ filterText, onFilter, onClear }) => (
  <>
    <TextField autoFocus id="filter" type="text" placeholder="Disabled" disabled aria-label="Search Input" value={filterText} onChange={onFilter} />
    <Button variant="contained" color="secondary" disabled type="button" onClick={onClear}>
      X
    </Button>
  </>
);

const columns = [
  {
    name: "Brand",
    selector: "brand",
    sortable: true,
    maxWidth: "10%",
  },
  {
    name: "Reference Number",
    selector: "ref",
    sortable: true,
    maxWidth: "10%",
  },
  {
    name: "Item Name",
    selector: "iname",
    sortable: true,
    wrap: true,
  },
  {
    name: "GTIN",
    selector: "gtin",
    sortable: true,
    maxWidth: "10%",
  },
  {
    name: "Quantity",
    selector: "qty",
    sortable: true,
    maxWidth: "5%",
  },
];

export default class Warehouse extends Component {
  state = {
    references: [],
    transactions: [],
    merged: [],
    loading: true,
    filterText: "",
    resetPaginationToggle: false,
    filtered: "",
  };

  handleClear = () => {
    const { resetPaginationToggle } = this.state;
    if (this.state.filterText) {
      this.setState({
        resetPaginationToggle: !resetPaginationToggle,
        filterText: "",
        filtered: this.state.merged,
      });
    }
  };

  getSubHeaderComponent = () => {
    return (
      <FilterComponent
        onFilter={(e) => {
          console.log("filtered");
          let newFilterText = e.target.value;
          this.setState({ filtered: this.state.merged.filter((item) => item["ref"] && item["ref"].toLowerCase().includes(newFilterText.toLowerCase())) });
          this.setState({ filterText: newFilterText });
        }}
        onClear={this.handleClear}
        filterText={this.state.filterText}
      />
    );
  };

  componentDidMount = () => {
    this.getReferenceList();
    this.getSubHeaderComponent();
  };

  getReferenceList = () => {
    console.log("Running Get Reference List");
    axios
      .get(`${REACT_APP_MURL}/reference`)
      .then((response) => {
        this.setState({ references: response.data });
        this.setState({ loading: false });
        console.log(response.data);
        this.getTransactionList();
      })
      .catch(() => {});
  };

  getTransactionList = () => {
    console.log("Running Get Transaction List List");
    axios
      .get(`${REACT_APP_MURL}/transaction/`)
      .then((response) => {
        console.log(response.data);
        let res = response.data.reduce((ac, a) => {
          let ind = ac.findIndex((x) => x.gtin === a.gtin);
          ind === -1 ? ac.push(a) : (ac[ind].qty += a.qty);
          return ac;
        }, []);
        this.setState({ transactions: res });
      })
      .catch(() => {});
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevState.transactions !== this.state.transactions) {
      let mergeddata = this.state.references.map((x) =>
        Object.assign(
          x,
          this.state.transactions.find((y) => y.gtin === x.gtin)
        )
      );
      this.setState({ merged: mergeddata });
    }
  }

  render() {
    return (
      <div className="printfull" id="Content">
        <Backdrop className="backdrop" open={this.state.loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <h1>Warehouse Overview</h1>
        <DataTable expandableRows expandableRowDisabled={(row) => row.disabled} subHeader subHeaderComponent={this.getSubHeaderComponent()} expandOnRowClicked highlightOnHover columns={columns} data={this.state.merged} defaultSortField="brand" expandableRowsComponent={<ExpandableComponent auth={this.props.auth} />} />
      </div>
    );
  }
}
