import React, { Component } from "react"
import { Link } from "react-router-dom"

import "./SidebarLink.css"

export default class SidebarLink extends Component {
	render() {
		return (
			<li>
				<Link to={this.props.location}>
					<i className={`sideicon fas fa-lg ${this.props.icon}`}></i>
					<span className='sidetext'>{this.props.name}</span>
				</Link>
			</li>
		)
	}
}
