import React, { useState, useEffect } from "react";
import axios from "axios";
import bwipjs from "bwip-js";
import DataTable from "react-data-table-component";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import { BiCube, BiFullscreen, BiCalendar, BiBarcode } from "react-icons/bi";
import dayjs from "dayjs";
import ToolsSidebar from "./Tools/ToolsSidebar";

const { REACT_APP_MURL } = process.env;

const FilterComponent = ({ filterText, onFilter, onClear }) => (
  <>
    <TextField autoFocus id="filter" type="text" placeholder="Filter By Ref#" aria-label="Search Input" value={filterText} onChange={onFilter} />
    <Button variant="contained" color="secondary" type="button" onClick={onClear}>
      X
    </Button>
  </>
);

function Tools() {
  const [data, setData] = useState([]);
  const [form, setForm] = useState([]);
  const [submission, setSubmission] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [filterText, setFilterText] = React.useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);
  const filteredItems = data.filter((item) => item["ref"] && item["ref"].toLowerCase().includes(filterText.toLowerCase()));
  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return <FilterComponent onFilter={(e) => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />;
  }, [filterText, resetPaginationToggle]);
  const columns = [
    { name: "Brand", selector: "brand", sortable: true },
    { name: "Reference Number", selector: "ref", sortable: true },
    { name: "Item Name", selector: "iname", sortable: true, minWidth: "35%" },
    {
      name: "Action",
      button: true,
      cell: (row) => (
        <button className="newbutton" data-gtin={row["gtin"]} data-ref={row["ref"]} onClick={handleClick}>
          Create
        </button>
      ),
    },
  ];

  useEffect(() => {
    axios
      .get(`${REACT_APP_MURL}/reference`)
      .then((response) => {
        setData(response.data);
        //console.log(response.data);
      })
      .catch(() => {});
  }, []);

  const handleClick = (event) => {
    setOpen(true);
    setSubmission({ ...submission, gtin: event.target.dataset.gtin, ref: event.target.dataset.ref });
    setForm({
      gtin: event.target.dataset.gtin,
    });
  };

  const handlebaropen = () => {
    handleCanvas();
  };

  const handleClose = () => {
    setSubmission({});
    setOpen(false);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setSubmission({
      ...submission,
      gtin: event.target[0].value,
      lot: event.target[1].value.toUpperCase(),
      exp: event.target[2].value,
    });
    handleCanvas();
    window.print();
  };

  const handleCanvas = () => {
    bwipjs.toCanvas(
      "mycanvas",
      {
        bcid: "gs1datamatrix", // Barcode type
        includetext: true,
        showborder: true,
        text: "(01)" + submission.gtin + "(17)" + submission.exp + "(10)" + submission.lot, // Text to encode
        scale: 3, // 3x scaling factor
      },
      5000
    );
  };

  const handleLot = (e) => {
    setSubmission({ ...submission, lot: e.target.value.toUpperCase() });
    handleCanvas();
  };

  const handleExp = (e) => {
    setSubmission({ ...submission, exp: e.target.value });
    console.log(dayjs("20" + e.target.value, `YYMMDD`).format("MMMM DD YYYY"));
    handleCanvas();
  };

  return (
    <div id="Content" className="no-print">
      <ToolsSidebar />
      <h1>Barcode Creator</h1>
      <DataTable defaultSortField="brand" columns={columns} data={filteredItems} subHeader subHeaderComponent={subHeaderComponentMemo} pagination paginationResetDefaultPage={resetPaginationToggle} />
      <Dialog fullWidth maxWidth="md" onEntered={handlebaropen} open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle className="no-print" id="form-dialog-title">
          Add Product to Inventory Database
        </DialogTitle>
        <form onSubmit={handleSubmit}>
          <DialogContent>
            <DialogContentText className="no-print">To create this label enter info. (Use NODATE and NOLOT if neccesary)</DialogContentText>
            <TextField autoComplete="off" className="no-print" autoFocus margin="dense" id="gtin" defaultValue={form.gtin} label="GTIN" fullWidth />
            <TextField autoComplete="off" className="uppercase no-print" onChange={handleLot} autoFocus margin="dense" id="lot" label="Lot Number" fullWidth />
            <TextField autoComplete="off" className="no-print" onChange={handleExp} margin="dense" id="exp" label="Expiration Date (YYMMDD)" fullWidth />
          </DialogContent>
          <div id="barcodeprint">
            <canvas id="mycanvas"></canvas>
            <ul>
              <li>
                <BiCube />
                &nbsp; {submission.ref}
              </li>
              <li>
                <BiFullscreen />
                &nbsp; {submission.lot === "NOLOT" ? "No Lot Provided" : submission.lot}
              </li>
              <li>
                <BiCalendar />
                &nbsp; {submission.exp === "NODATE" ? "No Date Provided" : dayjs("20" + submission.exp, `YYMMDD`).format("YYYY-MM-DD")}&nbsp;
              </li>
              <li>
                <BiBarcode />
                &nbsp; {"(01)" + submission.gtin + "(17)" + submission.exp + "(10)" + submission.lot}
              </li>
            </ul>
          </div>
          <DialogActions>
            <Button className="no-print" onClick={handleClose} color="primary">
              Close
            </Button>
            <Button className="no-print" type="submit" color="primary">
              Print
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}

export default Tools;
