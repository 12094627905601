import React from "react";
import { useGoogleLogout } from "react-google-login";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";

const clientId = "648638630908-30netn0bns8gvb23hoio9066k6364dd2.apps.googleusercontent.com";

function UserArea(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [open, setOpen] = React.useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen((prev) => !prev);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpen(false);
  };
  const onLogoutSuccess = (res) => {
    console.log("Logged out Success");
    window.location.reload();
  };

  const onFailure = () => {
    console.log("Handle failure cases");
  };

  const { signOut } = useGoogleLogout({
    clientId,
    onLogoutSuccess,
    onFailure,
  });

  return (
    <div id="sideuser" aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
      <img id="sideimg" src={props.auth.imageUrl} alt="Profile" />
      <span id="sidename">{props.auth.name}</span>
      {/*
        <button onClick={signOut} className="button">
          {console.log(props.auth)}
          {<span className="buttonText">Sign out</span>}
        </button>
      */}
      <ClickAwayListener mouseEvent="onMouseDown" touchEvent="onTouchStart" onClickAway={handleClose}>
        <Menu id="simple-menu" anchorEl={anchorEl} keepMounted open={open}>
          <MenuItem onClick={signOut}>Logout</MenuItem>
        </Menu>
      </ClickAwayListener>
    </div>
  );
}

export default UserArea;
