import React, { Component } from "react";
import DataTable from "react-data-table-component";
import bark from "bark-js";
import axios from "axios";
import { CSVLink } from "react-csv";
import moment from "moment";
import Tooltip from "@material-ui/core/Tooltip";
import Zoom from "@material-ui/core/Zoom";
import Fab from "@material-ui/core/Fab";
import Description from "@material-ui/icons/Description";
import Print from "@material-ui/icons/Print";
import QueueOutlinedIcon from "@material-ui/icons/QueueOutlined";
import { decode } from "hibc-parser";

const { REACT_APP_MURL } = process.env;

export default class Inbound extends Component {
  state = {
    parsespeed: 500,
    vendor: "Sophic",
    test: "",
    parsetitle: "F",
    gtinsub: [], //wtf is this
    scanlist: [],
    input: "",
    price: "0",
    master: "",
    slave: "",
    tester: [],
  };

  columns = [
    { name: "Quantity", selector: "qty", sortable: true, maxWidth: "10%" },
    { name: "Brand", selector: "brand", sortable: true },
    { name: "Reference Number", selector: "ref", sortable: true },
    { name: "Item Name", selector: "iname", sortable: true, minWidth: "35%", omit: true },
    { name: "GTIN", selector: "gtin", sortable: true, omit: true },
    { name: "Lot", selector: "lot", sortable: true },
    { name: "Expiration", selector: "exp", sortable: true },
    { name: "Vendor", selector: "vend", sortable: true },
    { name: "Price", selector: "price", sortable: true },
    {
      name: "Action",
      cell: (row) => (
        <>
          <button className="newbutton addbutton no-print" aria-label="Add" id={row.ID} onClick={() => this.handleAdd(row)}>
            +
          </button>
          <button className="newbutton subbutton no-print" disabled={row.qty === 1} aria-label="Subtract" id={row.ID} onClick={() => this.handleSub(row)}>
            -
          </button>
          <button className="newbutton deletebutton no-print" aria-label="delete" id={row.ID} onClick={() => this.handleRemove(row)}>
            R
          </button>
        </>
      ),
    },
  ];

  ordercolumns = [
    { name: "Quantity", selector: "qty", sortable: true, maxWidth: "10%" },
    { name: "Reference Number", selector: "ref", sortable: true },
  ];

  // ACCUMULATOR
  componentDidUpdate(prevProps, prevState) {
    if (prevState.scanlist !== this.state.scanlist) {
      const result = this.state.scanlist.reduce((acc, curr) => {
        // check if an object with the current ref value exists in the accumulator
        const ndx = acc.findIndex((e) => e.ref === curr.ref);
        if (ndx > -1) {
          // if it exists, increment the qty
          acc[ndx].qty += curr.qty;
        } else {
          // if it does not exist, push a new object to the accumulator
          acc.push({
            ref: curr.ref,
            qty: curr.qty,
          });
        }
        return acc;
      }, []);

      this.setState({ combinedref: result });
      //console.log(result);
    }
  }

  handleAdd = (e) => {
    this.setState((prevState) => ({
      scanlist: prevState.scanlist.map((row) =>
        row.ref === e.ref && row.lot === e.lot && row.vend === e.vend && row.price === e.price
          ? {
              ...row,
              qty: row.qty + 1,
            }
          : row
      ),
    }));
  };
  handleSub = (e) => {
    this.setState((prevState) => ({
      scanlist: prevState.scanlist.map((row) =>
        row.ref === e.ref && row.lot === e.lot && row.vend === e.vend && row.price === e.price
          ? {
              ...row,
              qty: row.qty - 1,
            }
          : row
      ),
    }));
  };

  handleRemove = (e) => {
    //console.log(e);
    const arrayCopy = this.state.scanlist.filter((row) => row.ref + row.lot + row.vend + row.price !== e.ref + e.lot + e.vend + e.price);
    this.setState({ scanlist: arrayCopy });
  };

  changeSubmit = (e) => {
    const val = e.target.value;
    if (this.timeout) clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.setState({ input: val });

      // GSA
      try {
        const parser = bark(this.state.input, { fnc: "?" });
        const gtinpull = parser.elements.find((element) => element.ai === "01");
        const lotpull = parser.elements.find((element) => element.ai === "10");
        const datepull = parser.elements.find((element) => element.ai === "17");
        const gtin = gtinpull.value;
        const lot = lotpull.value;
        const price = this.state.price;
        const vendor = this.state.vendor;

        if (typeof datepull === "undefined" || datepull.raw === "NODATE") {
          this.date = "N/A";
        } else {
          this.date = datepull.value; //GSA SPECIFIC //HSBC REMOVE .value
        }

        let obj = this.state.scanlist.find((x) => x.lot === lot && x.gtin === gtin && x.vend === vendor && x.price === price);
        if (obj == null) {
          axios
            .post(`${REACT_APP_MURL}/reference/search`, { gtin })
            .then((response) => {
              if (response.data[0] !== undefined) {
                //console.log("Item Found in Reference Database");
                this.setState((prevState) => ({ scanlist: [...prevState.scanlist, { brand: response.data[0].brand, ref: response.data[0].ref, iname: response.data[0].iname, gtin: response.data[0].gtin, exp: this.date, lot: lot, qty: 1, vend: vendor, price: this.state.price }] }));
                //new Audio("http://www.soundjay.com/button/beep-07.wav").play(); // Success Sound
              } else {
                // new Audio("http://www.soundjay.com/button/beep-10.wav").play(); // Fail Sound
                alert("Item Not Found in Reference Database Please add Product");
              }
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          const maparray = this.state.scanlist.map((x) => {
            if (x.lot === lot && x.gtin === gtin && x.vend === vendor && x.price === price) {
              return { ...x, qty: Number(x.qty) + 1 };
            }
            return x;
          });
          this.setState({ scanlist: maparray });
          //new Audio("http://www.soundjay.com/button/beep-07.wav").play(); // Success Sound
        }
        document.getElementById("inputarea").value = "";
      } catch (error) {
        if (error.name === "Error") {
          console.log("Trying HSBC");
          const hsbc = decode(this.state.input);
          console.log(hsbc);

          //const gtin = parser.elements.find((element) => element.ai === "01");
          const datepull = moment(hsbc.date).format("YYYY-MM-DD");
          const gtin = "50" + hsbc.product.padStart(12, "0");
          const lot = hsbc.lot;

          if (typeof datepull === "undefined" || datepull.raw === "NODATE") {
            this.date = "N/A";
          } else {
            this.date = datepull;
          }

          let obj = this.state.scanlist.find((x) => x.lot === lot && x.gtin === gtin);
          if (obj == null) {
            axios
              .post(`${REACT_APP_MURL}/reference/search`, { gtin })
              .then((response) => {
                if (response.data[0] !== undefined) {
                  //console.log("Item Found in Reference Database");
                  this.setState((prevState) => ({ scanlist: [...prevState.scanlist, { brand: response.data[0].brand, ref: response.data[0].ref, iname: response.data[0].iname, gtin: response.data[0].gtin, exp: this.date, lot: lot, qty: 1 }] }));
                  //new Audio("http://www.soundjay.com/button/beep-07.wav").play(); // Success Sound
                } else {
                  //new Audio("http://www.soundjay.com/button/beep-10.wav").play(); // Fail Sound
                  alert("Item Not Found in Reference Database Please add Product");
                }
              })
              .catch((error) => {
                console.log(error);
              });
          } else {
            const maparray = this.state.scanlist.map((x) => {
              if (x.lot === lot && x.gtin === gtin) {
                return { ...x, qty: Number(x.qty) + 1 };
              }
              return x;
            });
            this.setState({ scanlist: maparray });
            //new Audio("http://www.soundjay.com/button/beep-07.wav").play(); // Success Sound
          }
          document.getElementById("inputarea").value = "";

          console.log(gtin);
        }
      }
    }, this.state.parsespeed);
  };

  finalize = (e) => {
    //console.log(this.state.scanlist);

    this.state.scanlist.forEach((element) => {
      const glvp = element.gtin + element.lot + element.vend + element.price;
      console.log(element);
      axios
        .post(`${REACT_APP_MURL}/transaction/search`, { glvp })
        .then((response) => {
          if (response.data[0] == null) {
            // Create
            console.log("create");
            console.log(this.props.auth);
            axios
              .post(`${REACT_APP_MURL}/transaction/add`, { gtin: element.gtin, lot: element.lot, exp: element.exp, glvp: glvp, qty: element.qty, vend: element.vend, price: element.price })
              .then((response) => {})
              .catch((error) => {
                console.log(error);
              });

            axios
              .post(`${REACT_APP_MURL}/log/add`, {
                type: "Inbound",
                user: this.props.auth.name,
                ref: element.ref,
                gtin: element.gtin,
                qty: element.qty,
                lot: element.lot,
                exp: element.exp,
                vend: element.vend,
                ptracking: this.state.master,
              })
              .then((response) => {})
              .catch((error) => {
                console.log(error);
              });
          } else {
            // Update
            console.log("update");
            console.log(this.props);
            const upqty = response.data[0].qty + element.qty;
            axios
              .put(`${REACT_APP_MURL}/transaction/update`, { glvp: glvp, qty: upqty })
              .then((response) => {})
              .catch((error) => {
                console.log(error);
              });

            axios
              .post(`${REACT_APP_MURL}/log/add`, {
                type: "Inbound",
                user: this.props.auth.name,
                ref: element.ref,
                gtin: element.gtin,
                qty: element.qty,
                lot: element.lot,
                exp: element.exp,
                vend: element.vend,
                ptracking: this.state.master,
              })
              .then((response) => {})
              .catch((error) => {
                console.log(error);
              });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    });

    this.setState({ scanlist: [] });
  };

  printer = () => {
    window.print();
  };

  speed = () => {
    if (this.state.parsespeed === 500) {
      this.setState({ parsespeed: 2000 });
      this.setState({ parsetitle: "M" });
    }
    if (this.state.parsespeed === 2000) {
      this.setState({ parsespeed: 5000 });
      this.setState({ parsetitle: "S" });
    }
    if (this.state.parsespeed === 5000) {
      this.setState({ parsespeed: 500 });
      this.setState({ parsetitle: "F" });
    }
  };

  vendor = () => {
    if (this.state.vendor === "SurgiShop") {
      this.setState({ vendor: "Sophic" });
    }
    if (this.state.vendor === "Sophic") {
      this.setState({ vendor: "SurgiShop" });
    }
  };

  handleTrack = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handlePrice = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  render() {
    return (
      <div className="printfull" id="Content">
        <div className="flexcont">
          <div className="printfull column col-9">
            <h1>
              Inbound List beta({this.state.master}) {this.state.slave}:
              <br />
            </h1>

            <DataTable columns={this.columns} data={this.state.scanlist} noDataComponent="" />
          </div>
          <div className="no-print column col-3">
            <input autoComplete="off" id="inputarea" rows="4" onChange={this.changeSubmit} />
            <div id="buttoncontainer">
              <Tooltip TransitionComponent={Zoom} title="Submit">
                <div>
                  <Fab color="primary" aria-label="Submit" onClick={this.finalize} disabled={this.state.master.length < 1}>
                    <QueueOutlinedIcon />
                  </Fab>
                </div>
              </Tooltip>

              <Tooltip TransitionComponent={Zoom} title="Excel Export">
                <CSVLink
                  data={this.state.scanlist.map((v) => ({ ...v, "Invoice Number": this.state.master, "Outbound Date": moment().format("MMMM Do YYYY, h:mm:ss a") }))}
                  onClick={() => {
                    console.log(this.state.scanlist.concat([{ "Invoice Number": this.state.master, "Outbound Date": moment().format("MMMM Do YYYY, h:mm:ss a") }])); // 👍🏻 Your click handling logic
                  }}
                  filename={moment().format("MMMM Do YYYY, h:mm:ss a") + ".csv"}
                >
                  <Fab
                    color="primary"
                    style={{
                      color: "#fff",
                      backgroundColor: "#388e3c",
                    }}
                    aria-label="Excel Export"
                  >
                    <Description />
                  </Fab>
                </CSVLink>
              </Tooltip>
              <Tooltip TransitionComponent={Zoom} title="Print">
                <Fab
                  color="primary"
                  onClick={this.printer}
                  style={{
                    color: "#fff",
                    backgroundColor: "#996633",
                  }}
                  aria-label="Speed Toggle"
                >
                  <Print />
                </Fab>
              </Tooltip>
              <Tooltip TransitionComponent={Zoom} title="Parse Speed">
                <Fab
                  color="primary"
                  onClick={this.speed}
                  style={{
                    color: "#fff",
                    backgroundColor: "#666699",
                  }}
                  aria-label="Speed Toggle"
                >
                  <b>{this.state.parsetitle}</b>
                </Fab>
              </Tooltip>
              <Tooltip TransitionComponent={Zoom} title="Vendor">
                <Fab
                  color="primary"
                  onClick={this.vendor}
                  style={{
                    color: "#fff",
                    backgroundColor: "#336699",
                  }}
                  aria-label="Speed Toggle"
                >
                  <b>{this.state.vendor}</b>
                </Fab>
              </Tooltip>
              <Tooltip TransitionComponent={Zoom} title="Vendor">
                <Fab
                  color="primary"
                  style={{
                    color: "#fff",
                    backgroundColor: "#ff9100",
                  }}
                  aria-label="Speed Toggle"
                >
                  <input autoComplete="off" id="inputprice" rows="4" name="price" value={this.state.price} onChange={this.handlePrice} />
                </Fab>
              </Tooltip>
            </div>
            <input autoComplete="off" id="inputship" placeholder="Primary Tracking" rows="4" name="master" value={this.state.master} onChange={this.handleTrack} />
            <input autoComplete="off" id="inputshipx" placeholder="Seconday Tracking" rows="4" name="slave" value={this.state.slave} onChange={this.handleTrack} />
            <DataTable columns={this.ordercolumns} data={this.state.combinedref} noDataComponent="" />
          </div>
        </div>
      </div>
    );
  }
}
