import React, { Component } from "react";
import SidebarLink from "./SidebarLink.js";

import { Switch, Route } from "react-router-dom";
import HomeSidebar from "./HomeSidebar.js";
import WarehouseSidebar from "./WarehouseSidebar.js";
import Sales from "./Sales.js";

import "./Sidebar.css";

import UserArea from "./UserArea.js";

export default class Sidebar extends Component {
  render() {
    return (
      <div className="no-print" id="Sidebar">
        <a href="/">
          <img src="/images/logo.png" id="sidebar__logo" alt="logo" />
        </a>
        <ul>
          <SidebarLink name="Home" icon="fa-home" location="/" />
          <SidebarLink name="Warehouse" icon="fa-box" location="/Warehouse" />
          {/*<SidebarLink name="Sales" icon="fa-dollar-sign" location="/Sales" />
          <SidebarLink name="Tools" icon="fa-box" location="/Tools" />*/}

          <li className="divider"></li>
          <Switch>
            <Route path="/" exact component={HomeSidebar} />
            <Route path="/Warehouse" component={WarehouseSidebar} />
            <Route path="/Sales" component={Sales} />
          </Switch>
        </ul>
        <UserArea auth={this.props.auth} />
      </div>
    );
  }
}
