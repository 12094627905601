import React, { useState, useEffect } from "react";
import axios from "axios";
import DataTable from "react-data-table-component";
import ToolsSidebar from "./Tools/ToolsSidebar";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";

const { REACT_APP_MURL } = process.env;

const FilterComponent = ({ filterText, onFilter, onClear }) => (
  <>
    <TextField autoFocus id="filter" type="text" placeholder="Filter By Reference Number" aria-label="Search Input" value={filterText} onChange={onFilter} />
    <Button variant="contained" color="secondary" type="button" onClick={onClear}>
      X
    </Button>
  </>
);

function Logger() {
  const [data, setData] = useState([]);
  const [filterText, setFilterText] = React.useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);
  const filteredItems = data.filter((item) => item["user"] && item["user"].toLowerCase().includes(filterText.toLowerCase()));
  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return <FilterComponent onFilter={(e) => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />;
  }, [filterText, resetPaginationToggle]);
  const columns = [
    { name: "Action Type", selector: "type", sortable: true, maxWidth: "10%" },
    { name: "User", selector: "user", sortable: true, maxWidth: "10%" },
    {
      name: "Action",
      cell: (row) => (
        <span>
          {row["type"] === "Inbound" ? (
            <span>
              Inbound {row["qty"]}
              &nbsp;{row["ref"]}
              &nbsp;with lot: {row["lot"]}
              &nbsp;and expiration: {row["exp"]}
              &nbsp;from tracking: {row["ptracking"]}
            </span>
          ) : undefined}
          {row["type"] === "Outbound" ? (
            <span>
              Outbound {row["qty"]}
              &nbsp;{row["ref"]}
              &nbsp;with lot: {row["lot"]}
              &nbsp;and expiration: {row["exp"]}
              &nbsp;to invoice: {row["invoice"]}
            </span>
          ) : undefined}
          {row["type"] === "Reverse" ? (
            <span>
              Reverse {row["qty"]}
              &nbsp;{row["ref"]}
              &nbsp;with lot: {row["lot"]}
              &nbsp;and expiration: {row["exp"]}
              &nbsp;to invoice: {row["invoice"]}
              &nbsp;({row["notes"]})
            </span>
          ) : undefined}
          {row["type"] === "ProductAdd" ? (
            <span>
              Added Product {row["qty"]}
              &nbsp;{row["ref"]}
              &nbsp;and gtin: {row["gtin"]}
            </span>
          ) : undefined}
          {row["type"] === "Update" ? (
            <span>
              Update {row["qty"]}
              &nbsp;{row["ref"]}
              &nbsp;with lot: {row["lot"]}
              &nbsp;and expiration: {row["exp"]}
              &nbsp;<b>Notes:</b> {row["notes"]}
            </span>
          ) : undefined}
        </span>
      ),
    },
  ];

  useEffect(() => {
    axios
      .get(`${REACT_APP_MURL}/log`)
      .then((response) => {
        console.log(response.data);
        setData(response.data);
      })
      .catch(() => {});
  }, []);

  return (
    <div id="Content" className="no-print">
      <ToolsSidebar />
      <h1>User Logs</h1>
      <DataTable defaultSortField="brand" columns={columns} data={filteredItems} subHeader subHeaderComponent={subHeaderComponentMemo} pagination paginationResetDefaultPage={resetPaginationToggle} />
    </div>
  );
}

export default Logger;
