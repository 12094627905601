import React, { Component } from "react";
import DataTable from "react-data-table-component";
import bark from "bark-js";
import axios from "axios";
import { CSVLink } from "react-csv";
import moment from "moment";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import Zoom from "@material-ui/core/Zoom";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";

import Fab from "@material-ui/core/Fab";
import Description from "@material-ui/icons/Description";
import Print from "@material-ui/icons/Print";
import QueueOutlinedIcon from "@material-ui/icons/QueueOutlined";

const { REACT_APP_MURL } = process.env;

export default class Outbound extends Component {
  state = {
    parsespeed: 500,
    test: "",
    parsetitle: "F",
    gtinsub: [], //wtf is this
    scanlist: [],
    input: "",
    master: "",
    slave: "",
    invoicelist: [],
    open: false,
    lotopen: false,
    form: [],
    translist: [],
    currentinfo: [],
    formreason: "",
    printtable: false,
  };

  columns = [
    { name: "Max", selector: "max", sortable: true, maxWidth: "10%" },
    { name: "Quantity", selector: "qty", sortable: true, maxWidth: "10%" },
    { name: "Brand", selector: "brand", sortable: true },
    { name: "Reference Number", selector: "ref", sortable: true },
    {
      name: "Item Name",
      selector: "iname",
      sortable: true,
      minWidth: "35%",
      omit: true,
    },
    { name: "GTIN", selector: "gtin", sortable: true, omit: true },
    { name: "Lot", selector: "lot", sortable: true },
    { name: "Expiration", selector: "exp", sortable: true },
    { name: "Vendor", selector: "vend", sortable: true },
    { name: "Price", selector: "price", sortable: true },
    {
      name: "Action",
      cell: (row) => (
        <>
          <button
            className="newbutton addbutton no-print"
            disabled={row.qty >= row.max}
            aria-label="Add"
            id={row.ID}
            onClick={() => this.handleAdd(row)}
          >
            +
          </button>
          <button
            className="newbutton subbutton no-print"
            disabled={row.qty === 1}
            aria-label="Subtract"
            id={row.ID}
            onClick={() => this.handleSub(row)}
          >
            -
          </button>
          <button
            className="newbutton deletebutton no-print"
            aria-label="delete"
            id={row.ID}
            onClick={() => this.handleRemove(row)}
          >
            R
          </button>
        </>
      ),
    },
  ];

  ordercolumns = [
    { name: "Quantity", selector: "qty", sortable: true, maxWidth: "10%" },
    { name: "Reference Number", selector: "ref", sortable: true },
  ];

  printcolumns = [
    { name: "Quantity", selector: "qty", sortable: true, maxWidth: "10%" },
    { name: "Reference Number", selector: "ref", sortable: true },
    {
      name: "Item Name",
      selector: "iname",
      sortable: true,
      minWidth: "35%",
      omit: true,
    },
    { name: "GTIN", selector: "gtin", sortable: true, omit: true },
    { name: "Lot", selector: "lot", sortable: true },
    { name: "Expiration", selector: "exp", sortable: true },
  ];

  invoicecolumns = [
    { name: "QTY", selector: "qty", sortable: true, width: "15%" },
    { name: "Ref", selector: "ref", sortable: true },
    { name: "Lot", selector: "lot", sortable: true },
    { name: "Expiration", selector: "exp", sortable: true, omit: true },
    { name: "ID", selector: "_id", sortable: true, omit: true },
    { name: "GTIN", selector: "gtin", sortable: true, omit: true },
    {
      name: "Action",
      button: true,
      cell: (row) => (
        <button
          className="newbutton deletebutton"
          data-gtin={row["gtin"]}
          data-qty={row["qty"]}
          data-lot={row["lot"]}
          data-exp={row["exp"]}
          data-notes={row["notes"]}
          data-ref={row["ref"]}
          data-_id={row["_id"]}
          onClick={this.handleModify}
        >
          -
        </button>
      ),
    },
  ];

  componentDidUpdate(prevProps, prevState) {
    if (prevState.scanlist !== this.state.scanlist) {
      const result = this.state.scanlist.reduce((acc, curr) => {
        // check if an object with the current ref value exists in the accumulator
        const ndx = acc.findIndex((e) => e.ref === curr.ref);
        if (ndx > -1) {
          // if it exists, increment the qty
          acc[ndx].qty += curr.qty;
        } else {
          // if it does not exist, push a new object to the accumulator
          acc.push({
            ref: curr.ref,
            qty: curr.qty,
          });
        }
        return acc;
      }, []);

      this.setState({ combinedref: result });
      console.log(result);
    }
  }
  handleAdd = (e) => {
    this.setState((prevState) => ({
      scanlist: prevState.scanlist.map((row) =>
        row.ref === e.ref &&
        row.lot === e.lot &&
        row.vend === e.vend &&
        row.price === e.price
          ? {
              ...row,
              qty: row.qty + 1,
            }
          : row
      ),
    }));
  };
  handleSub = (e) => {
    this.setState((prevState) => ({
      scanlist: prevState.scanlist.map((row) =>
        row.ref === e.ref &&
        row.lot === e.lot &&
        row.vend === e.vend &&
        row.price === e.price
          ? {
              ...row,
              qty: row.qty - 1,
            }
          : row
      ),
    }));
  };

  handleListItemClick = (transresponse) => {
    console.log(this.state.currentinfo.ref);
    console.log(transresponse);

    this.setState((prevState) => ({
      scanlist: [
        ...prevState.scanlist,
        {
          max: transresponse.qty,
          vend: transresponse.vend,
          price: transresponse.price,
          lot: transresponse.lot,
          gtin: transresponse.gtin,
          exp: transresponse.exp,
          brand: this.state.currentinfo.brand,
          ref: this.state.currentinfo.ref,
          //iname: refresponse.data[0].iname,
          qty: 1,
        },
      ],
    }));
    this.setState({ lotopen: false });
  };

  handleRemove = (e) => {
    //console.log(e);
    const arrayCopy = this.state.scanlist.filter(
      (row) =>
        row.ref + row.lot + row.vend + row.price !==
        e.ref + e.lot + e.vend + e.price
    );
    this.setState({ scanlist: arrayCopy });
  };

  handleModify = (event) => {
    console.log(event.target.dataset._id);
    this.setState({ open: true });
    this.setState({ form: event.target.dataset });
  };

  handleReverse = (event) => {
    event.preventDefault();
    console.log(this.state.form);

    //TRICKY SHIT HERE
    const gtinlot = this.state.form.gtin + this.state.form.lot;
    console.log(this.state.form);
    axios
      .post(`${REACT_APP_MURL}/transaction/search`, { gtinlot })
      .then((response) => {
        if (response.data[0] == null) {
          // Create
          console.log("create");
          axios
            .post(`${REACT_APP_MURL}/transaction/add`, {
              gtin: this.state.form.gtin,
              lot: this.state.form.lot,
              exp: this.state.form.exp,
              gtinlot: gtinlot,
              qty: Number(this.state.form.qty),
            })
            .then((response) => {
              axios
                .post(`${REACT_APP_MURL}/log/delete`, {
                  _id: this.state.form._id,
                })
                .then((response) => {
                  axios
                    .post(`${REACT_APP_MURL}/log/add`, {
                      type: "Reverse",
                      user: this.props.auth.name,
                      ref: this.state.form.ref,
                      gtin: this.state.form.gtin,
                      qty: this.state.form.qty,
                      lot: this.state.form.lot,
                      exp: this.state.form.exp,
                      notes: this.state.formreason,
                      invoice: this.state.master,
                    })
                    .then((response) => {
                      this.setState({ open: false });
                      this.setState({ formreason: "" });
                    })
                    .catch((error) => {
                      console.log(error);
                    });
                })
                .catch((error) => {
                  console.log(error);
                });
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          // Update
          console.log("update");
          const upqty =
            Number(response.data[0].qty) + Number(this.state.form.qty);
          axios
            .put(`${REACT_APP_MURL}/transaction/update`, {
              gtinlot: gtinlot,
              qty: upqty,
            })
            .then((response) => {
              axios
                .post(`${REACT_APP_MURL}/log/delete`, {
                  _id: this.state.form._id,
                })
                .then((response) => {
                  axios
                    .post(`${REACT_APP_MURL}/log/add`, {
                      type: "Reverse",
                      user: this.props.auth.name,
                      ref: this.state.form.ref,
                      gtin: this.state.form.gtin,
                      qty: this.state.form.qty,
                      lot: this.state.form.lot,
                      exp: this.state.form.exp,
                      invoice: this.state.master,
                    })
                    .then((response) => {
                      this.setState({ open: false });
                      this.setState({ formreason: "" });
                    })
                    .catch((error) => {
                      console.log(error);
                    });
                })
                .catch((error) => {
                  console.log(error);
                });
            })
            .catch((error) => {
              console.log(error);
            });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  handleReason = (event) => {
    this.setState({ formreason: event.target.value });
    console.log(this.state.formreason);
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  handleCloseLotPicker = () => {
    this.setState({ lotopen: false });
  };

  changeSubmit = (e) => {
    const val = e.target.value;
    if (this.timeout) clearTimeout(this.timeout);
    this.timeout = setTimeout(
      () => {
        this.setState({ input: val });
        const parser = bark(this.state.input, { fnc: "?" });
        console.log(parser.elements);
        const gtinpull = parser.elements.find((element) => element.ai === "01");
        const lotpull = parser.elements.find((element) => element.ai === "10");
        const datepull = parser.elements.find((element) => element.ai === "17");
        const gtin = gtinpull.value;
        const lot = lotpull.value;

        if (typeof datepull === "undefined" || datepull.raw === "NODATE") {
          this.date = "N/A";
        } else {
          this.date = datepull.value;
        }

        let obj = this.state.scanlist.find(
          (x) => x.lot === lot && x.gtin === gtin && x.max !== x.qty
        );
        if (obj == null) {
          axios
            .post(`${REACT_APP_MURL}/reference/search`, { gtin })
            .then((refresponse) => {
              if (refresponse.data[0] !== undefined) {
                console.log("Item Found in Reference Database");
                console.log(refresponse);

                axios
                  .post(`${REACT_APP_MURL}/transaction/searchgl`, { gtin, lot })
                  .then((transresponse) => {
                    console.log(transresponse);
                    console.log(transresponse.data.length);

                    if (transresponse.data.length === 0) {
                      alert("No results found in inventory");
                      console.log("zero");
                    }
                    if (transresponse.data.length === 1) {
                      console.log("One");
                      this.setState((prevState) => ({
                        scanlist: [
                          ...prevState.scanlist,
                          {
                            max: transresponse.data[0].qty,
                            vend: transresponse.data[0].vend,
                            price: transresponse.data[0].price,
                            brand: refresponse.data[0].brand,
                            ref: refresponse.data[0].ref,
                            iname: refresponse.data[0].iname,
                            gtin: refresponse.data[0].gtin,
                            exp: this.date,
                            lot: lot,
                            qty: 1,
                          },
                        ],
                      }));
                    }

                    if (transresponse.data.length > 1) {
                      console.log("multiple");
                      this.setState({ lotopen: true });
                      this.setState({ translist: transresponse.data });
                      this.setState({
                        currentinfo: {
                          ref: refresponse.data[0].ref,
                          brand: refresponse.data[0].brand,
                        },
                      });
                    }
                  })
                  .catch((error) => {
                    console.log(error);
                  });

                //new Audio("http://www.soundjay.com/button/beep-07.wav").play(); // Success Sound
              } else {
                //new Audio("http://www.soundjay.com/button/beep-10.wav").play(); // Fail Sound
                alert(
                  "Item Not Found in Reference Database Please add Product"
                );
              }
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          const maparray = this.state.scanlist.map((x) => {
            if (x.lot === lot && x.gtin === gtin) {
              return { ...x, qty: Number(x.qty) + 1 };
            }
            return x;
          });
          this.setState({ scanlist: maparray });
          //new Audio("http://www.soundjay.com/button/beep-07.wav").play(); // Success Sound
        }

        document.getElementById("inputarea").value = "";
      },

      this.state.parsespeed
    );
  };

  finalize = (e) => {
    console.log(this.state.scanlist);

    this.state.scanlist.forEach((element) => {
      const gtinlot = element.gtin + element.lot;
      axios
        .post(`${REACT_APP_MURL}/transaction/search`, { gtinlot })
        .then((response) => {
          if (response.data[0] == null) {
            // ERROR NOT FOUND
            console.log("create");
            alert("Lot Number not found in inventory");
          } else {
            // UPDATE OR DELETE

            const upqty = response.data[0].qty - element.qty;
            console.log(upqty);
            if (upqty >= 1) {
              console.log("update");
              axios
                .put(`${REACT_APP_MURL}/transaction/update`, {
                  gtinlot: gtinlot,
                  qty: upqty,
                })
                .then((response) => {})
                .catch((error) => {
                  console.log(error);
                });

              axios
                .post(`${REACT_APP_MURL}/log/add`, {
                  type: "Outbound",
                  user: this.props.auth.name,
                  ref: element.ref,
                  gtin: element.gtin,
                  qty: element.qty,
                  lot: element.lot,
                  exp: element.exp,
                  invoice: this.state.master,
                })
                .then((response) => {})
                .catch((error) => {
                  console.log(error);
                });
            }
            if (upqty === 0) {
              console.log("delete");
              axios
                .post(`${REACT_APP_MURL}/transaction/delete`, {
                  gtinlot: gtinlot,
                })
                .then((response) => {
                  console.log(response);
                })
                .catch((error) => {
                  console.log(error);
                });

              axios
                .post(`${REACT_APP_MURL}/log/add`, {
                  type: "Outbound",
                  user: this.props.auth.name,
                  ref: element.ref,
                  gtin: element.gtin,
                  qty: element.qty,
                  lot: element.lot,
                  exp: element.exp,
                  invoice: this.state.master,
                })
                .then((response) => {})
                .catch((error) => {
                  console.log(error);
                });
            }
            if (upqty < 0) {
              console.log("delete");
              alert(
                element.ref +
                  " - " +
                  element.lot +
                  " Item skipped in inventory. Oubound was more that currentely in inventory"
              );
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    });

    this.setState({ scanlist: [] });
  };

  printer = () => {
    window.print();
  };

  speed = () => {
    if (this.state.parsespeed === 500) {
      this.setState({ parsespeed: 2000 });
      this.setState({ parsetitle: "M" });
    }
    if (this.state.parsespeed === 2000) {
      this.setState({ parsespeed: 5000 });
      this.setState({ parsetitle: "S" });
    }
    if (this.state.parsespeed === 5000) {
      this.setState({ parsespeed: 500 });
      this.setState({ parsetitle: "F" });
    }
  };

  printinv = () => {
    console.log("toggle");

    this.setState({ printtable: !this.state.printtable });
  };

  handleTrack = (e) => {
    this.setState({ [e.target.name]: e.target.value });
    if (this.timeout) clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      axios
        .post(`${REACT_APP_MURL}/log/search`, {
          type: "Outbound",
          invoice: this.state.master,
        })
        .then((response) => {
          console.log(response.data);
          this.setState({ invoicelist: response.data });
        })
        .catch((error) => {
          console.log(error);
        });
    }, 1000);
  };

  render() {
    return (
      <div className="printfull" id="Content">
        <div className="flexcont">
          <div className="printfull column col-9">
            <h1>
              Packing List ({this.state.master})
              <br />
            </h1>

            <DataTable
              columns={this.columns}
              data={this.state.scanlist}
              noDataComponent=""
            />

            {this.state.printtable === true && (
              <DataTable
                columns={this.printcolumns}
                data={this.state.invoicelist}
                noDataComponent=""
              />
            )}
          </div>
          <div className="no-print column col-3">
            <input
              autoComplete="off"
              id="inputarea"
              rows="4"
              onChange={this.changeSubmit}
            />
            <div id="buttoncontainer">
              <Tooltip TransitionComponent={Zoom} title="Submit">
                <div>
                  <Fab
                    color="primary"
                    aria-label="Submit"
                    onClick={this.finalize}
                    disabled={this.state.master.length < 1}
                  >
                    <QueueOutlinedIcon />
                  </Fab>
                </div>
              </Tooltip>

              <Tooltip TransitionComponent={Zoom} title="Excel Export">
                <CSVLink
                  data={this.state.scanlist.map((v) => ({
                    ...v,
                    "Invoice Number": this.state.master,
                    "Outbound Date": moment().format("MMMM Do YYYY, h:mm:ss a"),
                  }))}
                  onClick={() => {
                    console.log(
                      this.state.scanlist.concat([
                        {
                          "Invoice Number": this.state.master,
                          "Outbound Date": moment().format(
                            "MMMM Do YYYY, h:mm:ss a"
                          ),
                        },
                      ])
                    ); // 👍🏻 Your click handling logic
                  }}
                  filename={moment().format("MMMM Do YYYY, h:mm:ss a") + ".csv"}
                >
                  <Fab
                    color="primary"
                    style={{
                      color: "#fff",
                      backgroundColor: "#388e3c",
                    }}
                    aria-label="Excel Export"
                  >
                    <Description />
                  </Fab>
                </CSVLink>
              </Tooltip>
              <Tooltip TransitionComponent={Zoom} title="Print">
                <Fab
                  color="primary"
                  onClick={this.printer}
                  style={{
                    color: "#fff",
                    backgroundColor: "#996633",
                  }}
                  aria-label="Speed Toggle"
                >
                  <Print />
                </Fab>
              </Tooltip>
              <Tooltip TransitionComponent={Zoom} title="Parse Speed">
                <Fab
                  color="primary"
                  onClick={this.speed}
                  style={{
                    color: "#fff",
                    backgroundColor: "#666699",
                  }}
                  aria-label="Speed Toggle"
                >
                  <b>{this.state.parsetitle}</b>
                </Fab>
              </Tooltip>
              {this.state.invoicelist.length > 0 && (
                <Tooltip TransitionComponent={Zoom} title="Print Invoice">
                  <Fab
                    color="primary"
                    onClick={this.printinv}
                    style={{
                      color: "#fff",
                      backgroundColor: "#999944",
                    }}
                    aria-label="Print Invoice"
                  >
                    <b>P</b>
                  </Fab>
                </Tooltip>
              )}
            </div>
            <input
              autoComplete="off"
              id="inputship"
              placeholder="Invoice Number"
              rows="4"
              name="master"
              value={this.state.master}
              onChange={this.handleTrack}
            />
            <DataTable
              columns={this.ordercolumns}
              data={this.state.combinedref}
              noDataComponent=""
            />
            <DataTable
              columns={this.invoicecolumns}
              data={this.state.invoicelist}
              noDataComponent=""
            />
          </div>
        </div>

        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
          aria-labelledby="form-dialog-title"
        >
          <form onSubmit={this.handleReverse}>
            <DialogContent>
              <DialogContentText className="no-print">
                Are you Sure you want to Reverse the following Oubound
                Transaction? (Please note that doing this will add the following
                transaction back into the inventory)
              </DialogContentText>
              <DialogContentText className="no-print">
                <b>
                  {this.state.form.qty} units of {this.state.form.ref} with lot:{" "}
                  {this.state.form.lot} and expiration: {this.state.form.exp}
                </b>
              </DialogContentText>
              <TextField
                autoComplete="off"
                className="no-print"
                margin="dense"
                id="lot"
                defaultValue={this.state.form.reason}
                onChange={this.handleReason}
                label="Reason for Outbound Reversal"
                fullWidth
              />
            </DialogContent>
            <DialogActions>
              <Button
                className="no-print"
                onClick={this.handleClose}
                color="primary"
              >
                Close
              </Button>
              <Button className="no-print" type="submit" color="primary">
                Remove
              </Button>
            </DialogActions>
          </form>
        </Dialog>

        <Dialog
          open={this.state.lotopen}
          onClose={this.handleCloseLotPicker}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="simple-dialog-title">
            Select Vendor/Price {this.state.currentinfo.ref}
          </DialogTitle>
          <List>
            {this.state.translist.map((items) => (
              <ListItem
                button
                onClick={() => this.handleListItemClick(items)}
                divider
                key={items._id}
              >
                <ListItemText>
                  {items.qty} - {items.vend} (${items.price})
                </ListItemText>
              </ListItem>
            ))}
          </List>
        </Dialog>
      </div>
    );
  }
}
