import React, { useState, useEffect } from "react";
import ToolsSidebar from "./Tools/ToolsSidebar";
import axios from "axios";
import DataTable from "react-data-table-component";

import moment from "moment";
import MomentUtils from "@date-io/moment";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";

import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import Select from "@material-ui/core/Select";

import TextField from "@material-ui/core/TextField";

const { REACT_APP_MURL } = process.env;

export default function Report() {
  const [data, setData] = useState([]);
  const [dateRangeA, handledateRangeA] = useState(moment().startOf("month").format("YYYY-MM-DD"));
  const [dateRangeB, handledateRangeB] = useState(moment().format("YYYY-MM-DD"));
  const [inbound, setInbound] = React.useState("");
  const [Ref, setRef] = React.useState("");

  const handleInbound = (event) => {
    setInbound(event.target.value);
  };

  const handleRef = (event) => {
    setRef(event.target.value);
  };

  const columns = [
    { name: "Action Type", selector: "type", sortable: true, maxWidth: "10%" },
    { name: "User", selector: "user", sortable: true, maxWidth: "10%" },
    {
      name: "Date Added",
      selector: "createdAt",
      maxWidth: "20%",
      sortable: true,
      cell: (row) => <span>{moment(row["createdAt"]).format("MMMM Do YYYY, h:mm:ss a")}</span>,
    },
    {
      name: "Action",
      cell: (row) => (
        <span>
          {row["type"] === "Inbound" ? (
            <span>
              Inbound {row["qty"]}
              &nbsp;{row["ref"]}
              &nbsp;with lot: {row["lot"]}
              &nbsp;and expiration: {row["exp"]}
              &nbsp;from tracking: {row["ptracking"]}
            </span>
          ) : undefined}
          {row["type"] === "Outbound" ? (
            <span>
              Outbound {row["qty"]}
              &nbsp;{row["ref"]}
              &nbsp;with lot: {row["lot"]}
              &nbsp;and expiration: {row["exp"]}
              &nbsp;to invoice: {row["invoice"]}
            </span>
          ) : undefined}
          {row["type"] === "Reverse" ? (
            <span>
              Reverse {row["qty"]}
              &nbsp;{row["ref"]}
              &nbsp;with lot: {row["lot"]}
              &nbsp;and expiration: {row["exp"]}
              &nbsp;to invoice: {row["invoice"]}
              &nbsp;({row["notes"]})
            </span>
          ) : undefined}
          {row["type"] === "ProductAdd" ? (
            <span>
              Added Product {row["qty"]}
              &nbsp;{row["ref"]}
              &nbsp;and gtin: {row["gtin"]}
            </span>
          ) : undefined}
          {row["type"] === "Update" ? (
            <span>
              Update {row["qty"]}
              &nbsp;{row["ref"]}
              &nbsp;with lot: {row["lot"]}
              &nbsp;and expiration: {row["exp"]}
              &nbsp;<b>Notes:</b> {row["notes"]}
            </span>
          ) : undefined}
        </span>
      ),
    },
  ];

  useEffect(() => {
    console.log(dateRangeA);
    axios
      .post(`${REACT_APP_MURL}/log/searchdate`, { gte: dateRangeA, lte: dateRangeB, type: inbound, ref: Ref })
      .then((response) => {
        console.log(response.data);
        setData(response.data);
      })
      .catch(() => {});
  }, [dateRangeA, dateRangeB, inbound, Ref]);

  return (
    <div className="printfull" id="Content">
      <ToolsSidebar />
      <div className="flexcont">
        <div className="printfull column col-9">
          <DataTable defaultSortAsc={false} defaultSortField="invoice" columns={columns} data={data} subHeader />
        </div>
        <div className="no-print column col-3">
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <KeyboardDatePicker
              className="datepicker"
              value={dateRangeA}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
              autoOk={true}
              disableToolbar
              variant="inline"
              format="MM/DD/yyyy"
              margin="normal"
              label="Date Range Start"
              onChange={handledateRangeA}
            />
            <KeyboardDatePicker
              className="datepicker"
              value={dateRangeB}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
              autoOk={true}
              disableToolbar
              variant="inline"
              format="MM/DD/yyyy"
              margin="normal"
              label="Date Range End"
              onChange={handledateRangeB}
            />
          </MuiPickersUtilsProvider>
          <Select className="selectpicker" labelId="demo-simple-select-helper-label" id="demo-simple-select-helper" value={inbound} onChange={handleInbound}>
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            <MenuItem value={"Inbound"}>Inbound</MenuItem>
            <MenuItem value={"Outbound"}>Outbound</MenuItem>
            <MenuItem value={"ProductAdd"}>Product Add</MenuItem>
            <MenuItem value={"Reverse"}>Reverse</MenuItem>
            <MenuItem value={"Update"}>Update</MenuItem>
          </Select>
          <FormHelperText>Select to Queary by Action</FormHelperText>

          <TextField className="selectpicker" onBlur={handleRef} helperText="Select to Queary by Reference Number" />
          <TextField className="selectpicker" helperText="Select to Queary by Lot Number" />
          <TextField className="selectpicker" helperText="Select to Queary by Invoice" />
          <TextField className="selectpicker" helperText="Select to Queary by Tracking Number" />
        </div>
      </div>
    </div>
  );
}
