import React, { Component } from "react";
import SidebarLink from "./SidebarLink";

export default class WarehouseSidebar extends Component {
  render() {
    return (
      <div>
        <SidebarLink name="Overview" location="/Warehouse" />
        <SidebarLink name="Inbound" location="/Warehouse/Inbound" />
        <SidebarLink name="Outbound" location="/Warehouse/Outbound" />
        <SidebarLink name="New Product" location="/Warehouse/NewProduct" />
        <SidebarLink name="Tools" location="/Warehouse/Tools" />
        {/*<SidebarLink name="Kanban" location="/Warehouse/Kanban" /> */}
        {/*<SidebarLink name="Outbound" location="/" />
        <SidebarLink name="Adjustment" location="/" />
        <SidebarLink name="Transfer" location="/" />
      */}
      </div>
    );
  }
}
