import React, { useState, useEffect, useReducer } from "react";
import DataTable, { createTheme } from "react-data-table-component";
import axios from "axios";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import TextField from "@material-ui/core/TextField";

const { REACT_APP_MURL } = process.env;

createTheme("expand", {
  text: {
    primary: "#000000",
    secondary: "#2aa198",
  },
  background: {
    default: "#eee",
  },
  context: {
    background: "#cb4b16",
    text: "#FFFFFF",
  },
  divider: {
    default: "#ddd",
  },
});

function ExpandableComponent(data) {
  const [open, setOpen] = React.useState(false);
  const [resp, setResp] = useState([]);
  const [form, setForm] = useState([]);
  const [force, setForce] = useReducer((x) => x + 1, 0);

  const columns = [
    { name: "Lot", selector: "lot", sortable: true, maxWidth: "10%" },
    { name: "Expiration", selector: "exp", sortable: true, maxWidth: "10%" },
    { name: "Quantity", selector: "qty", sortable: true, maxWidth: "10%" },
    { name: "Vendor", selector: "vend", sortable: true, maxWidth: "10%" },
    { name: "Price", selector: "price", sortable: true, maxWidth: "10%" },
    { name: "Notes", selector: "notes", sortable: true, maxWidth: "30%" },
    {
      name: "Action",
      button: true,
      cell: (row) => (
        <DialogActions>
          <button className="newbutton" data-gtin={row["gtin"]} data-qty={row["qty"]} data-lot={row["lot"]} data-exp={row["exp"]} data-notes={row["notes"]} data-vend={row["vend"]} data-price={row["price"]} onClick={handleModify}>
            Modify
          </button>
          <button className="newbutton deletebutton" data-gtin={row["gtin"]} data-qty={row["qty"]} data-lot={row["lot"]} data-exp={row["exp"]} data-notes={row["notes"]} data-vend={row["vend"]} data-price={row["price"]} onClick={handleDelete}>
            Delete
          </button>
        </DialogActions>
      ),
    },
  ];

  const gtin = data.data.gtin;

  useEffect(() => {
    console.log("Component Rendered");

    axios
      .post(`${REACT_APP_MURL}/transaction/searchall`, { gtin })
      .then((response) => {
        setResp(response.data);
        console.log(response.data)
      })

      .catch((error) => {
        console.log(error);
      });

    // eslint-disable-next-line
  }, [force]);

  const handleModify = (event) => {
    setOpen(true);
    setForm({
      gtin: event.target.dataset.gtin,
      qty: event.target.dataset.qty,
      lot: event.target.dataset.lot,
      exp: event.target.dataset.exp,
      notes: event.target.dataset.notes,
      vend: event.target.dataset.vend,
      price: event.target.dataset.price,
    });
  };

  const handleDelete = (event) => {
    console.log(event.target.dataset);
    axios
      .post(`${REACT_APP_MURL}/transaction/delete`, { glvp: event.target.dataset.gtin + event.target.dataset.lot + event.target.dataset.vend + event.target.dataset.price })
      .then((response) => {
        console.log("success");
        axios
          .post(`${REACT_APP_MURL}/log/add`, {
            type: "Update",
            user: data.auth.name,
            ref: data.data.ref,
            gtin: event.target.dataset.gtin,
            vend: event.target.dataset.vend,
            qty: 0,
            lot: event.target.dataset.lot,
            exp: event.target.dataset.exp.toUpperCase(),
            notes: "Manually Deleted From Inventory",
          })
          .then((response) => {
            setForce();
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    axios
      .put(`${REACT_APP_MURL}/transaction/manupdate`, { glvp: event.target[0].value + event.target[1].value + event.target[2].value + event.target[7].value, lot: event.target[1].value, exp: event.target[3].value.toUpperCase(), qty: event.target[4].value, notes: event.target[5].value + " " + event.target[6].value })
      .then((response) => {
        //Update Component for new view of expandable content
        console.log("success");
        axios
          .post(`${REACT_APP_MURL}/log/add`, {
            type: "Update",
            user: data.auth.name,
            ref: data.data.ref,
            vend: event.target[2].value,
            gtin: event.target[0].value,
            qty: event.target[4].value,
            lot: event.target[1].value,
            exp: event.target[3].value.toUpperCase(),
            notes: event.target[5].value + " " + event.target[6].value,
          })
          .then((response) => {
            setForce();
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((error) => {
        console.log(error);
      });

    setOpen(false);
  };

  return (
    <div id="expandcont">
      {resp.length > 0 ? <DataTable theme="expand" columns={columns} data={resp} defaultSortField="exp" /> : <p>No Items in Inventory</p>}
      <Dialog fullWidth maxWidth="md" open={open} onClose={handleClose}>
        <form onSubmit={handleSubmit}>
          <DialogContent>
            <DialogContentText className="no-print">To edit this transaction fill in form. (Reason Required!)</DialogContentText>
            <TextField autoComplete="off" className="no-print" margin="dense" id="gtin" defaultValue={form.gtin} label="GTIN Disabled" fullWidth disabled />
            <TextField autoComplete="off" className="uppercase no-print" margin="dense" id="lot" defaultValue={form.lot} label="Lot Number Disabled" fullWidth disabled />
            <TextField autoComplete="off" className="uppercase no-print" margin="dense" id="vend" defaultValue={form.vend} label="Vendor Disabled" fullWidth disabled />
            <TextField autoComplete="off" className="no-print" margin="dense" id="exp" defaultValue={form.exp} label="Expiration Date (YYYY-MM-DD)" fullWidth />
            <TextField autoComplete="off" className="no-print" margin="dense" id="qty" defaultValue={form.qty} label="Quantity" fullWidth />
            <TextField autoComplete="off" className="no-print" margin="dense" id="notes" defaultValue={form.notes} label="Notes" fullWidth />
            <TextField autoComplete="off" className="no-print" margin="dense" id="reason" defaultValue="" label="Reason for Manual Modify" fullWidth />
            <TextField autoComplete="off" className="uppercase no-print" margin="dense" id="price" defaultValue={form.price} label="Price Disabled" fullWidth disabled />
          </DialogContent>
          <DialogActions>
            <Button className="no-print" onClick={handleClose} color="primary">
              Close
            </Button>
            <Button className="no-print" type="submit" color="primary">
              Modify
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}

export default ExpandableComponent;
