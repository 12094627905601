import React, { useEffect, useState, Fragment } from "react";

import Papa from "papaparse";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import TextField from "@material-ui/core/TextField";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";

import DataTable from "react-data-table-component";

import axios from "axios";

const { REACT_APP_MURL } = process.env;

const FilterComponent = ({ filterText, onFilter, onClear }) => (
  <>
    <TextField autoFocus id="filter" type="text" placeholder="Filter By Ref#" aria-label="Search Input" value={filterText} onChange={onFilter} />
    <Button variant="contained" color="secondary" type="button" onClick={onClear}>
      X
    </Button>
  </>
);

export default function App(props) {
  const [data, setData] = useState([]);
  const [form, setForm] = useState([]);
  const [submission, setSubmission] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [snopen, setSnopen] = React.useState(false);
  const [filterText, setFilterText] = React.useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);
  const filteredItems = data.filter((item) => item["SITE SKU"] && item["SITE SKU"].toLowerCase().includes(filterText.toLowerCase()));

  const columns = [
    { name: "Brand", selector: "BRAND", sortable: true },
    { name: "Site Sku", selector: "SITE SKU", sortable: true },
    { name: "Item Name", selector: "ITEM NAME", sortable: true, minWidth: "35%" },
    {
      name: "Action",
      button: true,
      cell: (row) => (
        <button className="newbutton" variant="contained" color="primary" data-ref={row["SITE SKU"]} data-brand={row["BRAND"]} data-iname={row["ITEM NAME"]} onClick={handleClick}>
          Create
        </button>
      ),
    },
  ];

  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return <FilterComponent onFilter={(e) => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />;
  }, [filterText, resetPaginationToggle]);

  useEffect(() => {
    //OLD CODE IF YOU SEE THIS AT A LATER POINT DELETE THIS AS IT MEANS NEW CODE WORKS YOU FOOL
    /*  Tabletop.init({
      key: "1QiT4kTAVwnwTIeSRhGhIaqis8QRY1X6S6L8V_Tw8ylc",
      simpleSheet: true,
    })
      .then((data) => {
        setData(data);
        //console.log(data);
      })
      .catch((err) => console.warn(err));
      */

    let url = `https://sheets.googleapis.com/v4/spreadsheets/1QiT4kTAVwnwTIeSRhGhIaqis8QRY1X6S6L8V_Tw8ylc/values/Inventory Template?key=AIzaSyBlmoalHYqqkiFWcUF0KtHqpfx22E4_fU0`;
    return fetch(url).then((response) =>
      response.json().then((result) => {
        let data = Papa.parse(Papa.unparse(result.values), { header: true });
        setData(data.data);
        console.log(data.data);
      })
    );
  }, []);

  useEffect(() => {
    // this hook captures the state change and this will have the correct value

    if (Object.keys(submission).length === 0) {
      //Check if state is empty? due to useFeffect being run on component render
    } else {
      console.log("Form Data Recieved");
      console.log(submission);
      axios
        .post(`${REACT_APP_MURL}/reference/add`, submission)
        .then((response) => {
          console.log(response);
          setSnopen(true);
        })
        .catch((error) => {
          console.log(error);
        });

      axios
        .post(`${REACT_APP_MURL}/log/add`, {
          type: "ProductAdd",
          user: props.auth.name,
          ref: submission.ref,
          gtin: submission.gtin,
        })
        .then((response) => {})
        .catch((error) => {
          console.log(error);
        });
    }
  }, [props.auth, submission]);

  const handleSubmit = (event) => {
    event.preventDefault();
    setOpen(false);
    setSubmission({
      ref: event.target[0].value,
      brand: event.target[1].value,
      iname: event.target[2].value,
      gtin: event.target[3].value,
      qty: 0,
    });
  };

  const handleClick = (event) => {
    setOpen(true);
    setForm({
      ref: event.target.dataset.ref,
      brand: event.target.dataset.brand,
      iname: event.target.dataset.iname,
    });
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSnclose = () => {
    setSnopen(false);
  };

  return (
    <div id="Content">
      <Snackbar open={snopen} autoHideDuration={6000} onClose={handleSnclose}>
        <Alert onClose={handleClose} severity="success">
          This is a success message!
        </Alert>
      </Snackbar>
      <div className="flexcont">
        <div className="column col-12">
          <h1>Data from Final Master Sheet</h1>

          <DataTable columns={columns} data={filteredItems} subHeader subHeaderComponent={subHeaderComponentMemo} pagination paginationResetDefaultPage={resetPaginationToggle} />

          <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">
              Add Product to Inventory Database
              <form onSubmit={handleSubmit}>
                <DialogContent>
                  <DialogContentText>To add this item please add GTIN. For custom items make sure the length is at least 14 digits</DialogContentText>
                  <TextField autoFocus margin="dense" id="ref" defaultValue={form.ref} label="Reference Number" fullWidth />
                  <TextField autoFocus margin="dense" id="brand" defaultValue={form.brand} label="Brand" fullWidth />
                  <TextField autoFocus margin="dense" id="iname" defaultValue={form.iname} label="Item Name" fullWidth />
                  <TextField autoFocus margin="dense" id="gtin" label="Gtin" fullWidth />
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleClose} color="primary">
                    Cancel
                  </Button>
                  <Button type="submit" color="primary">
                    Submit
                  </Button>
                </DialogActions>
              </form>
            </DialogTitle>
          </Dialog>
        </div>
      </div>
    </div>
  );
}
