import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { useState } from "react";
import Sidebar from "./components/Sidebar.js";
import Home from "./components/Home.js";
import Warehouse from "./components/Warehouse.js";
import NewProduct from "./components/Warehouse/NewProduct";
import Inbound from "./components/Warehouse/Inbound";
import Outbound from "./components/Warehouse/Outbound";
import Tools from "./components/Warehouse/Tools";
import Logger from "./components/Warehouse/Logger";
import Report from "./components/Warehouse/Report";
import Kanban from "./components/Warehouse/Kanban";
import Sales from "./components/Sales.js";
import { useGoogleLogin } from "react-google-login";

import "./App.css";

import { refreshTokenSetup } from "./components/utils/refreshToken.js";

export default function App() {
  const [authed, setAuthed] = useState(0);
  const [authdata, setAuthdata] = useState(0);

  const clientId = "648638630908-30netn0bns8gvb23hoio9066k6364dd2.apps.googleusercontent.com";

  const onSuccess = (res) => {
    //console.log(res.profileObj); //Outputs: Tabs1
    setAuthed(res.profileObj.email.substr(-13));
    setAuthdata(res.profileObj);
    refreshTokenSetup(res);
  };

  const onFailure = (res) => {
    console.log("Login failed: res:", res);
  };

  const { signIn } = useGoogleLogin({
    onSuccess,
    onFailure,
    clientId,
    isSignedIn: true,
    accessType: "offline",
    // responseType: 'code',
    // prompt: 'consent',
  });

  return (
    <Router>
      {(authed === "surgishop.com" && (
        <div className="App">
          <Sidebar auth={authdata} />
          <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/Warehouse" component={Warehouse}>
              <Warehouse auth={authdata} />
            </Route>
            <Route path="/Warehouse/NewProduct" component={NewProduct}>
              <NewProduct auth={authdata} />
            </Route>
            <Route exact path="/Warehouse/Inbound" component={Inbound}>
              <Inbound auth={authdata} />
            </Route>
            <Route path="/Warehouse/Outbound" component={Outbound}>
              <Outbound auth={authdata} />
            </Route>
            <Route path="/Warehouse/Tools" component={Tools} />
            <Route path="/Warehouse/Logger" component={Logger} />
            <Route path="/Warehouse/Report" component={Report} />
            <Route path="/Sales" component={Sales} />
            <Route path="/Warehouse/Kanban" component={Kanban}>
              <Kanban auth={authdata} />
            </Route>
          </Switch>
        </div>
      )) || (
        <div id="loginmodal">
          <div id="loginpopup">
            <h1>Login to Your Account</h1>
            <button onClick={signIn} type="button" className="loginbutton">
              Sign in with Google
            </button>
            <h2>(Please use your employee email)</h2>
          </div>
        </div>
      )}
    </Router>
  );
}
