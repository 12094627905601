import React, { Component } from "react";
import { Link } from "react-router-dom";

export default class ToolsSidebar extends Component {
  render() {
    return (
      <div id="toolnav">
        <ul>
          <li>
            <Link to="Tools">
              <span className="toolmenu">Barcode Creator</span>
            </Link>
            <Link to="Logger">
              <span className="toolmenu">User Logs</span>
            </Link>
            <Link to="Report">
              <span className="toolmenu">Reports</span>
            </Link>
          </li>
        </ul>
      </div>
    );
  }
}
