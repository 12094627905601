import React, { Component } from "react";

export default class Home extends Component {
  render() {
    return (
      <div className="printfull" id="Content">
        <h1>SurgiTeam Beta v.1.6.0</h1>
        <h2>Features in Progress</h2>
        <ul>
          <li>Daily Outbound Feature</li>
          <li>FEDEX/UPS Tracking Parse</li>
          <li>Custom Parse Barcode Creator</li>
          <li>Hide Action Column in Print</li>
        </ul>
        <h2>Changelog</h2>
        <ul>
          <li>
            <b>v.1.6.0</b>
          </li>
          <li>Added + and - adjuster to Inbound and Outbound</li>
          <li>Added Counter to Inbound and Outbound</li>
          <li></li>
          <li>
            <b>v.1.5.2</b>
          </li>
          <li>Warehouse Page Filter</li>
          <li>Invoice Print Button in Outbound</li>
          <li>
            <b>v.1.5.1</b>
          </li>
          <li>Report System W.I.P.</li>
          <li>Tracking/Invoice Border</li>
          <li>Inbound/Outbound Delete Button</li>
          <li>[BugFix] Multi Page Printing</li>
          <li>
            <b>v.1.5.0</b>
          </li>
          <li>Manual Delete Button</li>
          <li>
            <b>v.1.4.0</b>
          </li>
          <li>Logger</li>
          <li>Manual Adjustment Tool</li>
          <li>
            <b>v.1.3.0</b>
          </li>
          <li>Outbound</li>
          <li></li>
          <li>
            <b>v.1.2.0</b>
          </li>
          <li>Warehouse Quantities</li>
          <li>Barcode Creator</li>
          <li>
            <b>v.1.1.0</b>
          </li>
          <li>Dropdown for lot nubers</li>
          <li>Excel Export (updated)</li>
          <li>Tracking</li>
        </ul>
        <h2>Known Issues</h2>
      </div>
    );
  }
}
